import BaseAction from '@/Actions/BaseAction';
import {
  selectSessionWorkspaceID,
  setSherpaInitialized,
} from '@/Redux/Slices/SherpaContainerSlice';
import { selectFeaturesByMode } from '@/Redux/Slices/UISlice';
import {
  updateServerSHA,
  getLoginState,
  selectShaperHubLoggedIn,
  selectSyncUrl,
  getShaperSubscriptions,
  getShaperHubExternalItem,
  getLocale,
  getShaperHubFiles,
  selectLocale,
  getUser,
  getFirstWorkspace,
} from '@/Redux/Slices/ShaperHubSlice';
import { open, create, setSandboxMode } from '@/Sync/SyncThunks';
import { checkBrowserForSavedState } from '@/Redux/localStorage';
import ModalActions from './Modal';
import { setI18nLanguage } from '../i18n';
import AlertAction from './Alert';
import { initializeFonts } from '@/Helpers/FontHelper';
import { SvgOps } from '@/Geometry/SvgParser';
import {
  setEnabled,
  setSyncUrl,
  setWorkspaceIdFromUrlParam,
} from '@/Redux/Slices/SyncSlice';
import { entitlements } from '@/Helpers/Entitlements';
import {
  FilesystemObject,
  UserspaceExternalItemFileObject,
} from '@/@types/shaper-types';
import UIModeAction from './UIMode';

export type StudioUrlPathParams = {
  path?: string;
  newWorkspace?: boolean;
  workspaceId?: string;
  blobId?: string;
  duplicate?: string;
  preview?: boolean;
};

export default class InitializeAppAction extends BaseAction {
  modalAction: ModalActions;
  alertAction: AlertAction;
  uiModeAction: UIModeAction;

  constructor(...args: ConstructorParameters<typeof BaseAction>) {
    super(...args);
    this.modalAction = this.createAction(ModalActions);
    this.alertAction = this.createAction(AlertAction);
    this.uiModeAction = this.createAction(UIModeAction);
  }

  init = async (studioUrlParams: StudioUrlPathParams) => {
    const { dispatch } = this;
    await SvgOps.doInit();
    await dispatch(updateServerSHA());
    await dispatch(getLocale());

    //Need to check browser for saved state before getLoginState, because reducer will clear any browser saved demo states
    const browserHasSavedState = checkBrowserForSavedState();
    await dispatch(getLoginState());
    const loggedIn = this.useSelector(selectShaperHubLoggedIn);
    const useSync = this.useSelector((state) =>
      selectFeaturesByMode(state, entitlements.SYNC)
    );
    const syncUrl = this.useSelector(selectSyncUrl);
    const locale = this.useSelector(selectLocale);
    await dispatch(getUser());
    await dispatch(getShaperSubscriptions());

    setI18nLanguage(locale.language);

    if (useSync) {
      dispatch(setSyncUrl(syncUrl));
      if (loggedIn) {
        dispatch(setEnabled(true));
        const newWorkspacePath = studioUrlParams.path;
        const newWorkspace = studioUrlParams.newWorkspace;
        const workspaceId =
          studioUrlParams.workspaceId ??
          (await this.getMostRecentActiveWorkspaceId());
        const blobId = studioUrlParams.blobId;
        const isDuplicate = studioUrlParams.duplicate;
        const blobImportIntoNewCanvas =
          (blobId !== undefined || blobId === null) &&
          studioUrlParams.workspaceId === undefined;
        const isPreview = studioUrlParams.preview;

        await dispatch(getFirstWorkspace());

        //Create new workspace if:
        // 1. browser has saved state, create workspace and sync with current client state. It has already been purged from localStorage and sessionStorage at this point.
        // 2. newWorkspace flag is set
        // 3. If workspaceId is not provided and there are no recently modified workspaces to open.

        if (
          browserHasSavedState === true ||
          newWorkspace ||
          blobImportIntoNewCanvas ||
          !workspaceId
        ) {
          await dispatch(
            create({
              ...(newWorkspacePath && { path: newWorkspacePath }),
              ...(blobId && { blobId }),
            })
          );
        } else {
          await dispatch(
            open({
              workspaceId,
              ...(blobId && { blobId }),
              ...(isPreview && { isPreview }),
            })
          );
          await dispatch(getShaperHubExternalItem(workspaceId));
          if (isDuplicate) {
            this.alertAction.setDuplicateAlert();
          }
        }
      }
    }
    if (!useSync || !loggedIn) {
      //Sync disabled or if logged out, then set status to WORKSPACE_EDIT to enable sandbox mode
      if (studioUrlParams.workspaceId) {
        dispatch(setWorkspaceIdFromUrlParam(studioUrlParams.workspaceId));
      }
      dispatch(setSandboxMode());
      this.modalAction.openSignInModal();
    }
    dispatch(setSherpaInitialized());
    await initializeFonts();
  };

  getMostRecentActiveWorkspaceId = async () => {
    let recentWorkspaceId;

    try {
      const files = await this.dispatch(getShaperHubFiles()).unwrap();
      const filesystemObjectIsExternalObject = (
        f: FilesystemObject
      ): f is UserspaceExternalItemFileObject => f.type === 'external';

      // check if this session ID is a file
      const sessionWorkspaceID = this.useSelector(selectSessionWorkspaceID);
      if (files.find((file) => file?.externalItemId === sessionWorkspaceID)) {
        return sessionWorkspaceID;
      }

      //Most recent modified is first
      const sortedFiles = files
        .filter(filesystemObjectIsExternalObject)
        .filter((f) => f.externalItemType === 'studio-workspace')
        .sort((a, b) =>
          new Date(a.modified).getTime() > new Date(b.modified).getTime()
            ? -1
            : 1
        );
      recentWorkspaceId =
        sortedFiles.find((file) => file?.externalItemId)?.externalItemId ??
        null;

      if (recentWorkspaceId === null) {
        return null;
      }
      return recentWorkspaceId;
    } catch {
      return null;
    }
  };
}
