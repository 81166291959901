import { useDispatch } from 'react-redux';

// components
import CanvasActionMenu from '../CanvasActionMenu';

type Props = {
  edge: 'left' | 'right' | 'bottom';
  disableAll?: boolean;
};

// primary canvas menu
export default function PreviewActionMenu(props: Props) {
  const dispatch = useDispatch();

  function onEditDesign() {}

  // activates an action
  function onSelectAction(target: any) {
    // the action will manage the dispatch
    if (target.dispatch === false) {
      target.toggle();
    }
    // normal toggle
    else {
      dispatch(target.toggle(true));
    }
  }

  // create each action
  const actions = [
    {
      id: 'edit-design',
      toggle: onEditDesign,
      i18nKey: 'edit-design',
      className: 'action-badge',
      content: <div>Edit</div>,
    },
  ];

  return (
    <CanvasActionMenu
      onSelectAction={onSelectAction}
      className='canvas-context-action-menu'
      edge={props.edge}
      invert
      actions={actions}
      disableAll={props.disableAll}
      dataCy='canvas-context'
    />
  );
}
