/* eslint-disable valid-jsdoc */
import { UnknownAction } from '@reduxjs/toolkit';

import { sequenceUpdateRange } from './../SyncHelpers';
import {
  removeReconnectId,
  selectSnapshot,
  setSnapshot,
  setStatus,
  setWorkspace,
} from '../../Redux/Slices/SyncSlice';
import { open, getBaseUrl, syncAxios, getSnapshot } from './../SyncThunks';

import {
  validatePatches,
  applyPatches,
  immerToJSONPatches,
} from './../PatchGenerator';
import { setCanvasFromSyncSnapshot } from '@/Redux/Slices/CanvasSlice';
import { setLoading, updateOptions } from '@/Redux/Slices/SherpaContainerSlice';
import { getShaperHubExternalItem } from '@/Redux/Slices/ShaperHubSlice';
import { OpenOptions, Snapshot } from './../SyncConstants';
import { SyncError } from './../SyncError';
import { downloadBlob } from '@/ShaperHub/ShaperHubThunks';
import { log } from './../SyncLog';
import { context, SyncListenerApi } from '../SyncListener';
import { addInitialPatch } from './AddInitialPatch';
import { Workspace } from '@/@types/shaper-types';
import { FulfilledAction } from '@/Redux/hooks';
import {
  migrateCanvas,
  canvasRequiresMigration,
} from '@/Redux/migrateCanvasStore';
import { updateVersion } from '@/CanvasContainer/CanvasActions';
import { toggleRename } from '@/Redux/Slices/UISlice';
import { setupWorkspaceFromImport } from './SetupWorkspaceFromImport';
import { selectFeatureFlag } from '@/Redux/Slices/FeatureFlagsSlice';
import UIModeAction from '@/Actions/UIMode';
import { useSelector } from 'react-redux';

export const getAndApplyUpdates = async (
  workspace: Workspace,
  listenerApi: SyncListenerApi,
  snapshot: Snapshot,
  options: Partial<OpenOptions>
) => {
  const { dispatch, getState } = listenerApi;
  const { blobId, isDuplicate } = options;
  const store = getState();
  const { latestSnapshotSequence, latestUpdateSequence, id } = workspace;
  let newSnapshot = snapshot;
  if (
    latestUpdateSequence > 0 &&
    latestUpdateSequence > latestSnapshotSequence
  ) {
    log(
      `Getting and applying pending updates to snapshot`,
      { ...context, workspace },
      'debug'
    );
    for (const u of sequenceUpdateRange(
      latestSnapshotSequence,
      latestUpdateSequence
    )) {
      const updateResponse = await syncAxios.get(
        `${getBaseUrl(store)}/${id}/update/${u}`
      );

      const patches = await updateResponse.data;
      /**
       * validate patches and apply
       */
      const syncSnapshot = selectSnapshot();

      const jsonPatches = immerToJSONPatches(patches.flat());

      const validPatches = validatePatches(syncSnapshot, jsonPatches);
      if (validPatches) {
        if (typeof jsonPatches === 'boolean') {
          throw new SyncError(
            'bad_patches',
            'listener',
            `Unable to apply patches for update ${u}`,
            undefined,
            { validPatches }
          );
        } else {
          log(
            `Was able to get patches for update ${u}`,
            { ...context, jsonPatches: jsonPatches.length },
            'debug'
          );
          newSnapshot = applyPatches(newSnapshot, jsonPatches);
          dispatch(setSnapshot(newSnapshot));
        }
      }
    }
  }

  if (canvasRequiresMigration(newSnapshot)) {
    dispatch(setCanvasFromSyncSnapshot(newSnapshot.canvas));
    const migratedSnapshot = migrateCanvas(newSnapshot);
    dispatch(setStatus('migrating'));
    dispatch(updateVersion(migratedSnapshot));
  } else {
    const { canvas } = newSnapshot;
    dispatch(setCanvasFromSyncSnapshot(canvas));
    dispatch(setLoading(false));
    dispatch(setStatus('edit'));
    if (blobId) {
      log(
        `Open action included a blobId to import`,
        { ...context, blobId },
        'debug'
      );
      dispatch(downloadBlob({ blobId }));
    } else if (isDuplicate) {
      dispatch(
        updateOptions({
          showApplicationMenu: true,
        })
      );
      dispatch(toggleRename(true));
    }
  }
};

/**
 * Listener for the open thunk so that we can hydrate the workspace with the latest snapshot
 * and any outstanding updates. Contains logic to create an intial patch for a workspace if for some
 * reason it was created without it.
 */
export const addOpenListener = (startListening: Function) => {
  startListening({
    predicate: (action: UnknownAction) => {
      return open.fulfilled.match(action);
    },
    effect: async (action: FulfilledAction, listenerApi: SyncListenerApi) => {
      const { dispatch, getState } = listenerApi;
      const { blobId, isDuplicate, isPreview } = action.meta.arg;
      const state = getState();
      const workspace = action.payload;

      if (workspace) {
        dispatch(removeReconnectId());
        dispatch(setWorkspace(workspace));

        const hasProjectSharingFeatureFlag = selectFeatureFlag(
          state,
          'release-project-sharing'
        );
        if (hasProjectSharingFeatureFlag && isPreview) {
          const uiModeAction = new UIModeAction(dispatch, useSelector);
          uiModeAction.toPreview();
        }

        const {
          latestSnapshotSequence,
          latestUpdateSequence,
          id,
          blobId: blobIdFromWorkspace,
        } = workspace as Workspace;

        log(`Open was fulfilled for workspace ${id}`, {
          ...context,
          workspace,
        });

        dispatch(getShaperHubExternalItem(id));

        /**
         * If there are any updates, we know that there is an initial snapshot already
         */
        if (latestUpdateSequence > 0) {
          /**
           * If the snapshot sequence is greater than 0, we can hydrate the snapshot in the store with
           * the snapshot stored in s3
           */
          if (latestSnapshotSequence > 0) {
            log(
              `Hydrating the snapshot from sequence ${latestSnapshotSequence}`,
              { ...context, workspace },
              'debug'
            );
            await dispatch(getSnapshot({ workspace, blobId, isDuplicate }));
          } else {
            /**
             * Because we don't have a snapshot readily available, but we have updates, we will
             * get those updates and apply them instead
             */
            const snapshot = selectSnapshot();
            await getAndApplyUpdates(workspace, listenerApi, snapshot, {
              blobId,
              isDuplicate,
            });
          }
        } else {
          log(
            `Workspace does not have any updates or snapshots, going to add an initial patch`,
            { ...context, workspace }
          );
          if (blobIdFromWorkspace && latestUpdateSequence === 0) {
            await setupWorkspaceFromImport(listenerApi, blobIdFromWorkspace);
          } else {
            await addInitialPatch(listenerApi, blobId);
          }
        }
      } else {
        throw new SyncError(
          'unknown_workspace',
          'listener',
          'Unable to get the workspace from open thunk despite a successful response',
          undefined,
          {
            action,
          }
        );
      }
    },
  });
};
