import { updateFeatureFlags } from '@/Redux/Slices/FeatureFlagsSlice';
import { AppStore } from '@/Redux/store';
import { UnleashClient } from 'unleash-proxy-client';

const FEATURE_FLAGS = [
  'studio-fonts',
  'is-shaper-employee',
  'studio-developer-settings',
  'studio-debug-logs',
  'release-studio-free',
  'studio-polyline-feature',
  'studio-tessellation-feature',
  'release-project-sharing',
] as const;

export type FeatureFlagsMap = {
  // eslint-disable-next-line no-unused-vars
  [key in (typeof FEATURE_FLAGS)[number]]: boolean;
};

const unleash = new UnleashClient({
  appName: import.meta.env.VITE_UNLEASH_APP_NAME,
  clientKey: import.meta.env.VITE_UNLEASH_CLIENT_KEY,
  refreshInterval: 60 * 5, // 5 minutes
  url: 'https://unleash.shapertools.com/api/frontend',
});

export const FeatureFlagClient = {
  init: async (store: AppStore) => {
    const parseFlags = () => {
      return FEATURE_FLAGS.reduce((obj, item) => {
        return {
          ...obj,
          [item]: unleash.isEnabled(item),
        };
      }, store.getState().featureFlags.featureFlags);
    };

    unleash.on('ready', () => {
      store.dispatch(
        updateFeatureFlags(parseFlags(), store.getState().shaperHub.locale)
      );
    });
    unleash.on('update', () => {
      store.dispatch(
        updateFeatureFlags(parseFlags(), store.getState().shaperHub.locale)
      );
    });
    unleash.on('error', (error: unknown) => {
      console.log(error);
    });

    await unleash.start();
  },

  setUser: (user: { userId: string; email: string }) => {
    unleash.setContextField('userId', user.userId);
    unleash.setContextField('email', user.email);
  },
};
