import { FeatureFlagsMap } from '@/Helpers/FeatureFlagClient';
import { enableDebugging } from '@/Sync/SyncLog';
import { createSlice } from '@reduxjs/toolkit';
import { LocaleResponse } from '@/@types/shaper-types';
import { getDeveloperSettings } from '@/Utility/developer-settings';
import { setI18nLanguage } from '@/i18n';
import { RootState } from '../store';
import { SvgOps } from '@/Geometry/SvgParser';
import svgCache from '@/Geometry/SvgCache/SvgCache';

export const DEFAULT_FEATURE_FLAGS: FeatureFlagsMap = {
  'is-shaper-employee': false,
  'studio-debug-logs': false,
  'studio-developer-settings': false,
  'studio-fonts': false,
  'release-studio-free': false,
  'studio-polyline-feature': false,
  'studio-tessellation-feature': false,
  'release-project-sharing': false,
};

export const updateFeatureFlags = (
  featureFlags: FeatureFlagsMap,
  locale: LocaleResponse
) => ({
  type: 'featureFlags/featureFlagsUpdated',
  payload: {
    featureFlags,
    locale,
  },
});

export const initialFeatureFlagState = {
  featureFlags: DEFAULT_FEATURE_FLAGS,
};

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState: initialFeatureFlagState,
  reducers: {
    featureFlagsUpdated(
      state,
      action: {
        payload: { featureFlags: FeatureFlagsMap; locale: LocaleResponse };
        type: string;
      }
    ) {
      state.featureFlags = action.payload.featureFlags;

      if (state.featureFlags['studio-debug-logs']) {
        enableDebugging();
      }

      if (state.featureFlags['studio-developer-settings']) {
        const devLocale = getDeveloperSettings('locale');
        setI18nLanguage(devLocale || action.payload.locale.language);
      }

      if (state.featureFlags['studio-tessellation-feature']) {
        SvgOps.setFeatureFlag(true);
        svgCache.setFeatureFlag(true);
      }
    },
  },
});

export const selectFeatureFlags = (state: RootState) =>
  state.featureFlags.featureFlags;
export const selectTessellationFeatureFlag = (state: RootState) =>
  !!state.featureFlags.featureFlags['studio-tessellation-feature'];

export const selectFeatureFlag = (
  state: RootState,
  flagName: keyof FeatureFlagsMap
) =>
  state.featureFlags.featureFlags
    ? state.featureFlags.featureFlags[flagName]
    : false;
export default featureFlagsSlice.reducer;
