import { defaultSecretOptions } from '@/defaults';
import { selectSecretOptions } from '@/Redux/Slices/SherpaContainerSlice';
import SvgGroups from '@/UILayer/Components/SvgGroups';
import Viewport from '@/UILayer/Components/Viewport';
import UIState from '@/UILayer/State/UIState';
import { useSelector } from 'react-redux';

type Props = {
  ui: UIState;
};
export default function PreviewUI(props: Props) {
  const { ui } = props;

  const secretOptions = useSelector(selectSecretOptions);

  const {
    guideUnselectedPathWidth,
    guideUnselectedPathColor,
    guideSelectedPathWidth,
    guideSelectedPathColor,
    objectUnselectedPathColor,
    objectUnselectedPathWidth,
    objectSelectedPathColor,
    objectSelectedPathWidth,
  } = {
    ...defaultSecretOptions,
    ...secretOptions,
  };

  const styles = `
  .ui-layer .svg-group.reference.selected .pathGroup2 path {
        stroke: #E27718;
        stroke-width: 12px;
        stroke-opacity: 0.12;
        vector-effect: non-scaling-stroke;
      }
      .ui-layer .svg-group.reference.selected path {
        stroke: #${guideSelectedPathColor};
        stroke-width: ${guideSelectedPathWidth}px;
      }
      .ui-layer .svg-group.reference path {
        stroke: #${guideUnselectedPathColor};
        stroke-width: ${guideUnselectedPathWidth}px;
      }
      .ui-layer .svg-group.design.selected path
      {
        stroke: #${objectSelectedPathColor};
        stroke-width: ${objectSelectedPathWidth}px;
      }
      .ui-layer .svg-group.design .pathGroup.selected > .basePath
      {
        stroke: #${objectSelectedPathColor};
        stroke-width: ${objectSelectedPathWidth}px;
      }
      .ui-layer .svg-group.design path {
        stroke: #${objectUnselectedPathColor};
        stroke-width: ${objectUnselectedPathWidth}px;
      }`;

  return (
    <div className='ui-layer'>
      <style type='text/css'>{styles}</style>
      <Viewport viewport={ui.viewport}>{<SvgGroups ui={ui} />}</Viewport>
    </div>
  );
}
