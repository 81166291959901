import React from 'react';
import { useAction } from '@/Actions/useAction';
import { useSelector } from 'react-redux';

// selectors
import {
  selectUIMode,
  selectFeatures,
  selectUIModeOptionsMenuExpanded,
} from '@/Redux/Slices/UISlice';
import { selectSelectedGroupIds } from '@/Redux/Slices/SelectionSlice';

// actions
import UIModeAction from '@/Actions/UIMode';
import SetSelectionAction from '@/Actions/SetSelection';

// components
import ModeSelectionTab from './ModeSelectionTab';

// menus
import DesignModeOptionMenu from './OptionMenus/DesignModeOptionsMenu';
import PlanModeOptionsMenu from './OptionMenus/PlanModeOptionsMenu';
import { entitlements } from '@/Helpers/Entitlements';
import { Mode as ModeType } from '@/@types/shaper-types';

type UIModeActionFunc = 'toDefault' | 'toPlan' | 'toReview';

export type Mode = {
  name: string;
  modes: ModeType[];
  icon: string;
  menu: React.JSX.ElementType | null;
  activation: UIModeActionFunc;
  feature: string;
  i18nKey: string;
  selected?: boolean;
};

export default function ModeSelectionMenu() {
  // selectors
  const currentMode = useSelector(selectUIMode);
  const selection = useSelector(selectSelectedGroupIds);
  const expanded = useSelector(selectUIModeOptionsMenuExpanded);

  // actions
  const uiModeAction = useAction(UIModeAction);
  const selectionAction = useAction(SetSelectionAction);

  // modes
  const MODES: Mode[] = [
    {
      name: 'Design',
      modes: ['default', 'anchor-selection', 'text-editor', 'preview'],
      icon: 'mode-design',
      menu: DesignModeOptionMenu,
      activation: 'toDefault',
      feature: entitlements.DESIGN_MODE,
      i18nKey: 'design',
    },

    {
      name: 'Plan',
      modes: ['plan'],
      icon: 'mode-plan',
      menu: PlanModeOptionsMenu,
      activation: 'toPlan',
      feature: entitlements.PLAN_MODE,
      i18nKey: 'plan',
    },

    {
      name: 'Review',
      modes: ['review'],
      icon: 'mode-review',
      menu: null,
      activation: 'toReview',
      feature: entitlements.REVIEW_MODE,
      i18nKey: 'review',
    },
  ];

  // actions
  const selected = MODES.find((mode) => mode.modes.includes(currentMode));

  function onSwitchToPlanMode() {
    if (!selection.length) {
      selectionAction.selectAllPaths();
    }
  }

  // toggles the mode expansion
  function onToggleMode() {
    if (selected?.menu) {
      uiModeAction.toggleOptionsMenu(!expanded);
    }
  }

  // deactivates the mode menu
  function onActivateMode(mode: UIModeActionFunc) {
    if (/plan/i.test(mode)) {
      onSwitchToPlanMode();
    }

    uiModeAction[mode]();
    uiModeAction.toggleOptionsMenu(false);
  }

  function renderSelectedMenu() {
    if (selected) {
      const { menu: Menu } = selected;
      if (Menu) {
        return <Menu />;
      }
    }
  }

  return (
    <div
      className='mode-selection-menu'
      role='menu'
      data-cy='mode-selection-menu'
    >
      <div className='mode-selection-menu--modes'>
        <div className='mode-selection-menu--modes--container'>
          {MODES.filter((mode) => selectFeatures(mode.feature)).map((mode) => (
            <ModeSelectionTab
              {...mode}
              key={mode.name}
              selected={mode === selected}
              expanded={expanded}
              hasMenu={!!mode.menu}
              onActivateMode={() => onActivateMode(mode.activation)}
              onToggleMode={onToggleMode}
              dataCy={mode.i18nKey}
            />
          ))}
        </div>
      </div>

      {expanded && (
        <div
          className='mode-selection-menu--options'
          data-cy='mode-menu-options'
        >
          {renderSelectedMenu()}
        </div>
      )}
    </div>
  );
}
