import InteractionMode from '@/InteractionsManager/Modes/InteractionMode';

// interactions
import ZoomViewportInteraction from '@/InteractionsManager/Interactions/ZoomViewport';
import TranslateViewportInteraction from '@/InteractionsManager/Interactions/TranslateViewport';

import PanAndZoomViewportInteraction from '../../Interactions/PanAndZoomViewport';

export default class PreviewMode extends InteractionMode {
  theme = 'default';

  getInteractionHandlers() {
    return [
      TranslateViewportInteraction,
      ZoomViewportInteraction,
      PanAndZoomViewportInteraction,
    ];
  }
}
